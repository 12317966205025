.contentC {
    .bgCircle {
        height: 100vh;
        width: 140vh;
        position: absolute;
        bottom: -50%;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 50%;
        background-color: #ffecbf;
    }
    .bgHouses {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 2rem);
    }
}
@media screen and (min-width: 600px) {
    .contentC {
        .bgHouses {
            width: 460px;
        }
    }
}
@media screen and (min-width: 1000px) {
    .contentC {
        .bgHouses {
            width: 706px;
        }
    }
}
