.contentC {
    min-height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    // padding: 0 24px;
    .bodyC {
        padding: 32px 24px 0 24px;
    }
}

@media screen and (min-width: 1000px) {
    .contentC {
        .bodyC {
            padding-top: 32px;
        }
    }
}
