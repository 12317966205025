.contentC {
    background-color: white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    gap: 16px;
    max-width: 480px;
    width: min(calc(100% - 32px), 100%);
    margin: auto;
    > img {
        height: 56px;
        width: 56px;
    }
    > header {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 128%;
        text-align: center;
    }
    > p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 128%;
        text-align: center;
    }
}

@media screen and (min-width: 600px) {
    .contentC {
        > img {
            height: 96px;
            width: 96px;
        }
        > header {
            font-size: 32px;
        }
        > p {
            font-size: 20px;
        }
    }
}
