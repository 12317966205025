.contentC {
    padding: 10px 0;
    display: flex;
    // align-items: center;
    justify-content: center;
    position: relative;
    .logo {
        height: 32px;
    }
    .separator,
    .subTitle {
        display: none;
    }
    .policyC {
        height: 44px;
        width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e0815c;
        border-radius: 50%;
        position: absolute;
        z-index: 3;
        right: 24px;
        top: 50%;
        transform: translateY(-50%);
    }
    .disclaimerC {
        background-color: white;
        padding: 32px;
        width: min(calc(100% - 32px), 632px);
        max-width: 632px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;

        > header {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 128%;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
            > img {
                filter: invert(56%) sepia(65%) saturate(415%) hue-rotate(330deg) brightness(92%) contrast(91%);
                height: 24px;
                width: 24px;
            }
        }
        > p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            margin-bottom: 32px;
        }
        .btn {
            height: 56px;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 128%;
        }
    }
}

@media screen and (min-width: 600px) {
    .contentC {
        .disclaimerC {
            max-width: 480px;
            padding: 32px;
            > header {
                font-size: 24px;
            }
            > p {
                font-size: 16px;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    .contentC {
        padding: 12px 80px;
        justify-content: flex-start;
        align-items: center;
        gap: 24px;
        .separator {
            height: 40px;
            border: 1px solid var(--text-0);
            display: block;
        }
        .separator,
        .subTitle {
            display: block;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 128%;
        }
    }
}
