.contentC {
    > header {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        font-style: normal;
        font-weight: 700;
        line-height: 128%;
    }
}

@media screen and (min-width: 600px) {
    .contentC {
        > header {
            font-size: 48px;
        }
    }
}
@media screen and (min-width: 1000px) {
    .contentC {
        padding: 24px;
        > header {
            font-size: 56px;
        }
    }
}
