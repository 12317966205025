.contentC {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-bottom: 6rem;
    .left {
        margin-bottom: 12px;
        width: min(480px, 100%);
        > header {
            display: flex;
            align-items: center;
            gap: 12px;
            margin-bottom: 16px;
            > button > img {
                height: 32px;
                width: 32px;
            }
            > span {
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 128%;
            }
        }
        .unitDetails {
            background-color: white;
            width: 100%;
            border-radius: 12px;
            padding: 16px;
            display: flex;
            align-items: center;
            gap: 8px;
            > img {
                height: 32px;
                width: 32px;
            }
            > span {
                font-size: 16px;
                font-weight: 700;
            }
        }
    }
    .right {
        padding: 24px;
        width: min(480px, 100%);
        background-color: white;
        border-radius: 12px;
        .infoForm {
            .shrInput {
                height: 40px;
                outline: 1px solid #8c8f9a;
            }
            > header {
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 128%;
                text-align: center;
                margin-bottom: 8px;
            }
            .asterisk {
                color: #295bff;
                text-align: center;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 128%;
                margin-bottom: 24px;
            }
            .input {
                margin-bottom: 16px;
                display: block;
                > p {
                    margin-bottom: 8px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 128%;
                }
            }
            .voucherC {
                .uploadC {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 6px;
                    justify-content: center;
                    border: 2px dashed #527aff;
                    margin-bottom: 8px;
                    height: 135px;
                    border-radius: 12px;
                    > img {
                        height: 32px;
                        width: 32px;
                    }
                    > div {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 128%;
                        padding: 10px 24px;
                        border-radius: 24px;
                        border: 1px solid black;
                    }
                    > p {
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 128%;
                    }
                }
                .files {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    > li {
                        display: flex;
                        align-items: center;
                        padding: 4px 12px;
                        justify-content: space-between;
                        > button {
                            background-color: transparent;
                        }
                        &:hover {
                            background-color: #f5f5f5;
                            border-radius: 12px;
                            > button {
                                background-color: #9e9b9b;
                            }
                        }
                        &:not(:last-child) {
                            border-bottom: 1px solid #e0e0e0;
                        }
                    }
                }
                .checkboxC {
                    display: flex;
                    gap: 12px;
                    align-items: center;
                    > span {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 128%;
                        color: #666a79;
                    }
                }
            }

            .controlls {
                display: flex;
                flex-direction: column;
                gap: 24px;
                .summitBtn {
                    width: 100%;
                    height: 56px;
                }
            }
        }
    }
}
@media screen and (min-width: 600px) {
    .contentC {
        .left {
            > header {
                > span {
                    font-size: 32px;
                }
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    .contentC {
        padding: 24px;
        .left {
            .unitDetails {
                padding: 24px;
                gap: 12px;
                > img {
                    height: 48px;
                    width: 48px;
                }
                > span {
                    font-size: 24px;
                }
            }
        }
    }
    .contentC_desktop_stage1 {
        display: flex;
        flex-direction: row;
        gap: 50px;
        align-items: flex-start;
        justify-content: center;
        .right {
            max-width: 720px;
            flex-grow: 1;
            .infoForm {
                > header {
                    font-size: 32px;
                }
                .asterisk {
                    font-size: 16px;
                }
                .input {
                    > p {
                        font-size: 20px;
                    }
                }
                .voucherC {
                    .checkboxC {
                        > span {
                            font-size: 20px;
                        }
                    }
                }
                .captcha1 {
                    display: none;
                }
                .controlls {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 32px;
                    .summitBtn {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
