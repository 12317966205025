body {
    margin: 0;
    background-color: #ffe39b;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

@media screen and (min-width: 1000px) {
    .desktop_hide {
        display: none;
    }
}
