.contentC {
    > header {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 128%;
        text-align: center;
        margin-bottom: 32px;
    }
    .addressSearch {
        width: min(100%, 480px);
        margin: auto;
    }
}

@media screen and (min-width: 600px) {
    .contentC {
        > header {
            font-size: 32px;
            margin-bottom: 56px;
        }
    }
}
